import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();



document.addEventListener('DOMContentLoaded', function() {
    // Create category containers
    function createCategoryContainers(data) {
        function fetchAndPopulateCategory(category, listId) {
            const listElement = document.getElementById(listId);
            if (!listElement) return;

            category.items.forEach(item => {
                const listItem = document.createElement('li');
                listItem.textContent = item;
                listElement.appendChild(listItem);
            });
        }

        data.categories.forEach(category => {
            // Create the category container and nested collapsible and content
            const categoryContainer = document.createElement('div');
            categoryContainer.className = 'category-container';

            const categoryCollapsible = document.createElement('div');
            categoryCollapsible.className = `collapsible ${category.name.toLowerCase()}`;

            const nameDiv = document.createElement('div');
            nameDiv.textContent = category.name;

            const span1 = document.createElement('span');
            span1.textContent = `(${category.courseQty} courses | `;

            const span2 = document.createElement('span');
            span2.textContent = `${category.lessonQty} lessons)`;

            categoryCollapsible.appendChild(nameDiv);
            categoryCollapsible.appendChild(span1);
            categoryCollapsible.appendChild(span2);

            const categoryContent = document.createElement('div');
            categoryContent.className = 'content';

            const ul = document.createElement('ul');
            ul.id = `${category.name.toLowerCase()}List`;
            categoryContent.appendChild(ul);

            categoryContainer.appendChild(categoryCollapsible);
            categoryContainer.appendChild(categoryContent);

            document.getElementById('catalog').appendChild(categoryContainer);

            categoryCollapsible.addEventListener('click', function() {
                const content = this.nextElementSibling;
                content.classList.toggle('open');
            });

            fetchAndPopulateCategory(category, `${category.name.toLowerCase()}List`);
        });
    }

    // Fetch and create category containers
    fetch('assets/json/categories.json')
        .then(response => {
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            return response.json();
        })
        .then(data => createCategoryContainers(data))
        .catch(error => {
            console.error('Error fetching JSON data:', error);
            const catalog = document.getElementById('catalog');
            if (catalog) {
                catalog.textContent = 'Failed to load categories. Please try again later.';
            }
        });

    // Curriculum navigation logic
    document.querySelectorAll(".course .summary a").forEach(moreLink => {
        moreLink.addEventListener("click", (e) => {
            e.preventDefault();

            const parentCourse = moreLink.closest(".course");
            const isOpen = parentCourse.classList.contains("show-more");

            if (isOpen) {
                parentCourse.classList.remove("show-more");
                moreLink.textContent = "...details";
            } else {
                document.querySelectorAll(".course.show-more").forEach(course => {
                    course.classList.remove("show-more");
                    course.querySelector(".summary a").textContent = "...details";
                });

                parentCourse.classList.add("show-more");
                moreLink.textContent = "close details";
            }
        });
    });

    // Handle "close-trigger" click
    document.querySelectorAll(".course .close-trigger").forEach(closeTrigger => {
        closeTrigger.addEventListener("click", (e) => {
            e.preventDefault();

            const parentCourse = closeTrigger.closest(".course");
            parentCourse.classList.remove("show-more");
            parentCourse.querySelector(".summary a").textContent = "...details";
        });
    });

    // Handle keypress navigation
    document.addEventListener("keydown", (e) => {
        const currentCourse = document.querySelector(".course.show-more");
        if (!currentCourse) return;

        const allCourses = Array.from(document.querySelectorAll(".series .course"));
        const currentIndex = allCourses.indexOf(currentCourse);

        if (e.key === "ArrowDown" && currentIndex !== -1) {
            const nextCourse = allCourses[currentIndex + 1];
            if (nextCourse) {
                currentCourse.classList.remove("show-more");
                currentCourse.querySelector(".summary a").textContent = "...details";

                nextCourse.classList.add("show-more");
                nextCourse.querySelector(".summary a").textContent = "close details";
            }
        }

        if (e.key === "ArrowUp" && currentIndex !== -1) {
            const previousCourse = allCourses[currentIndex - 1];
            if (previousCourse) {
                currentCourse.classList.remove("show-more");
                currentCourse.querySelector(".summary a").textContent = "...details";

                previousCourse.classList.add("show-more");
                previousCourse.querySelector(".summary a").textContent = "close details";
            }
        }

        if (e.key === "Escape") {
            allCourses.forEach(course => {
                course.classList.remove("show-more");
                course.querySelector(".summary a").textContent = "...details";
            });
        }
    });
});
